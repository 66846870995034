/*!
    Revision History:
    VSS             DATE         INITIALS   PILOT ITEM #
    19.05.28.0000   05/28/2019   JB         T20190313.0171
*/
/* LoginForm Component */
.LoginForm.form-horizontal {
    margin-top: 10px;
}

.LoginForm.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.LoginForm .help-block {
    margin-top: 0px;
    margin-bottom: 0px;
}

.LoginForm .form-group-sm.has-warning {
    margin-bottom: 0px;
}

.LoginForm .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* AccountRecoveryForm Component */
.AccountRecoveryForm.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

/* RegistrationForm Component */
.RegistrationForm.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.RegistrationForm .error {
    color: red;
    float: left;
    text-align: left;
}

.RegistrationForm .registrationButtons {
    display: inline-block;
}

@media (min-width: 768px){
    .RegistrationForm .account-parts-container
    {
        display: inline-block;
        margin: 0px;
    }
}

.RegistrationForm .account-parts-container .form-label
{
    margin: 5px;
}

.RegistrationForm .account-parts-container .add-account-part-1
{
    width: 110px;
    display: inline-block;
    padding-right: 0px;
}

.RegistrationForm .account-parts-container .add-account-part-2,
.RegistrationForm .account-parts-container .add-account-part-3
{
    width: 80px;
    display: inline-block;
    padding-right: 0px;
}